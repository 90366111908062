import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo.png';
import '../styles/header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <Link to="/">
            {/* <h1>Hi-Tech Solar</h1> */}
            <img src={logo} alt="Hi-Tech Solar" className="logo-img" />
          </Link>
        </div>
      </div>
      <nav className="nav">
        <div className="container">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/solar-geyser">Solar Geysers</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
