import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Using Font Awesome for the arrow icon
import '../styles/ScrollToTopButton.css'; // Importing CSS file for styling

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > window.innerHeight / 2) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className={`scroll-to-top ${isVisible ? 'visible' : ''}`}>
            {isVisible && (
                <div onClick={scrollToTop}>
                    <FaArrowUp />
                </div>
            )}
        </div>
    );
};

export default ScrollToTopButton;