import React from 'react';
import '../styles/ContactPage.css'; // Import CSS file for styling
import Header from '../components/header';
import Footer from '../components/footer';
import ScrollToTopButton from '../components/ScrollToTopButton';

const ContactPage = () => {
  return (
    <div>
      <Header />
      <div className="contact-page">
        <h1>Contact us</h1>

        <hr />

        <div className="contact-info">
          <h2>Contact Information</h2>
          <div className="customer-care">
            <h3>Customer Care Line:</h3>
            <p>0027-11-472-8372</p>
            <p className="note">Please note: We don't sell DIY packages. We do all our installations in GAUTENG only! Please be aware of imitations and inferior geysers bought elsewhere!!!</p>
          </div>
          <div className="after-hours">
            <h3>After Hours:</h3>
            <p>073-346-9097 (Tony)</p>
            <p>081-490-9817 (Jessica)</p>
          </div>
          <div className="physical-location">
            <h3>Physical Location:</h3>
            <p>11 Petunia Street, Florida Park,</p>
            <p>Roodepoort, Gauteng 1709</p>
            <p>Republic of South Africa</p>
            <p>(By Appointment Only)</p>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};

export default ContactPage;