import React from 'react';
import Header from '../components/header'; // Import Header component
import '../styles/AboutPage.css'; // Import CSS file for styling
import Footer from '../components/footer';
import ScrollToTopButton from '../components/ScrollToTopButton';

const AboutPage = () => {
  return (
    <div>
      <Header />
      <div className="about-page">
        <h1>About us</h1>

        <hr />

        <div className="mission-section">
          <h2>MISSION</h2>
          <p>
            At Hi-Tech Solar, our mission is to harness solar solutions to
            alleviate high electricity costs while providing our customers with
            access to free hot water. By doing so, we contribute to cost savings,
            reduce greenhouse gas emissions and carbon footprints, eradicate
            poverty, and foster sustainable job creation.
          </p>
        </div>
        <div className="vision-section">
          <h2>VISION</h2>
          <p>
            Since 2004, we have been committed to achieving customer satisfaction
            through excellence in service and product delivery. As leaders in the
            solar industry and energy-saving products, we strive to offer the
            finest products available. Our dedication to maintaining superior
            standards ensures that our customers receive the quality we are
            renowned for. Through ongoing research and development, we continually
            innovate to deliver the most advanced products globally, maintaining
            our competitive edge in the market.
          </p>
        </div>
        <div className="values-section">
          <h2>VALUES</h2>
          <p>
            At Hi-Tech Solar, we hold our clients and end-users in high esteem.
            We prioritize delivering value for their investment and emphasize
            exceptional service and customer satisfaction. With a hands-on
            approach, we cater to both urban and rural communities, ensuring that
            everyone benefits from our solutions.
          </p>
        </div>
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
};

export default AboutPage;