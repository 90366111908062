import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Header from '../components/header';
import Footer from '../components/footer';
import ScrollToTopButton from '../components/ScrollToTopButton';
import heatmap from '../assets/heatmap.png';
import img1 from '../assets/4Oct2022.jpg';
import img2 from '../assets/05May2021-Bedfordview.jpg';
import img3 from '../assets/13Sep2022-Soweto.jpg';
import img4 from '../assets/19Apr2021-Randfontein.jpg';
import img5 from '../assets/23Nov2022-Emmarentia.jpg';
import img6 from '../assets/23Sep2021.jpg';
import img7 from '../assets/24Oct2022.jpg';
import img8 from '../assets/1629296073041.jpg';
import img9 from '../assets/Edenvale-1 14082021.jpg';
import img10 from '../assets/Hi-Tech Solar Demo.jpg';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <div>
      <Header />
      <div className="home-container">
        <h1>Welcome to Hi-Tech Solar</h1>
        <h2>Your Solar Geyser Solution</h2>

        <hr />

        <div className="introduction">
          <h3>Introduction</h3>
          <p>In Southern Africa, where solar radiation ranges from 5.0 kWh/m² to 6.9 kWh/m², inland areas receive some of the highest levels globally. This geographical advantage positions us to harness abundant, freely available solar energy efficiently.</p>
          <div className='heatmap'>
            <img src={heatmap} alt="Heat Map Image" />
          </div>
          <p>Unlike traditional electricity suppliers like Eskom, which faces challenges in meeting nationwide demand, leading to frequent load-shedding, Hi-Tech Solar (Pty) Ltd stands as a beacon of sustainable energy solutions. Specializing in the manufacturing, supply, and installation of advanced Evacuated Glass Tube Solar Water Systems, we deliver clean, green energy solutions across Southern Africa.</p>
          <p>Our solar geysers not only reduce reliance on conventional electricity by up to 60%, but they also contribute to cleaner air and a healthier environment. Built to last over 20 years with minimal maintenance, our products ensure long-term savings and reliability. Embracing solar energy means embracing a renewable, cost-effective resource that benefits both consumers and the planet.</p>
        </div>

        <br />

        <Carousel className= "carousel" showThumbs={true} infiniteLoop useKeyboardArrows autoPlay>
          <div>
            <img src={img1} alt="Solar Image 1" />
          </div>
          <div>
            <img src={img2} alt="Solar Image 2" />
          </div>
          <div>
            <img src={img3} alt="Solar Image 3" />
          </div>
          <div>
            <img src={img4} alt="Solar Image 4" />
          </div>
          <div>
            <img src={img5} alt="Solar Image 5" />
          </div>
          <div>
            <img src={img6} alt="Solar Image 6" />
          </div>
          <div>
            <img src={img7} alt="Solar Image 7" />
          </div>
          <div>
            <img src={img8} alt="Solar Image 8" />
          </div>
          <div>
            <img src={img9} alt="Solar Image 9" />
          </div>
          <div>
            <img src={img10} alt="Solar Image 10" />
          </div>
        </Carousel>

        <h3>So now... ask yourself:</h3>
        <ul>
          <li>Are you tired of paying exorbitant electricity bills?</li>
          <li>Do frequent load-shedding and power outages disrupt your daily life?</li>
          <li>Are you looking to harness free solar energy to save money on hot water?</li>
          <li>Do you seek affordable, high-quality solar solutions built to last?</li>
          <li>Are you committed to reducing your carbon footprint and greenhouse gas emissions?</li>
        </ul>
        <h3>Look no further. Talk to us now.</h3>
        <p>At Hi-Tech Solar, we specialize in providing sustainable solar geyser solutions that cater to a wide range of applications, including:</p>
        <ul>
          <li>Residential homes across all income brackets</li>
          <li>Estates, mixed-use developments, and housing complexes</li>
          <li>Commercial and industrial facilities</li>
          <li>Hospitality sector including hostels, hotels, and guest houses</li>
          <li>Healthcare institutions such as hospitals, clinics, and staff residences</li>
          <li>Shopping centers and malls</li>
          <li>Mining operations, stadiums, factories, and farms</li>
          <li>Educational institutions like universities, colleges, and boarding schools</li>
          <li>Recreational facilities including gyms, jacuzzis, and underfloor heating systems</li>
          <li>Food service industries including restaurants, fast food outlets, and catering businesses</li>
          <li>Childcare facilities, creches, and non-profit organizations</li>
        </ul>
        <p>Whether you're looking to save on energy costs, ensure a reliable hot water supply, or contribute to environmental sustainability, Hi-Tech Solar is here to help. Explore our range of solar geyser solutions designed to meet your specific needs and budget, backed by our commitment to quality and customer satisfaction.</p>
        <hr />
        <p>Join us in making a greener choice for a brighter future. Contact us today to learn more about how solar energy can benefit your home or business.</p>
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

export default HomePage;
