import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/SolarGeyserPage.css'; 
import ScrollToTopButton from '../components/ScrollToTopButton';
import analysisDiagram from '../assets/analysis_diagram.jpg';

const SolarGeyserPage = () => {
  return (
    <div>
      <Header />
      <div className="solar-geyser-container">
        <h1>Solar Geyser</h1>

        <hr />
        
        <div className="section">
          <h3>Technology:</h3>
          <p>
            The vacuum tube absorbs the heat through the coated layers and transfers it to the water. The water inside the tube is heated while the water in the tank stays cool. The heated water inside the vacuum tube goes up to the tank and the cold water in the tank goes down to the bottom of the vacuum tube. This physical reaction is called the thermosiphon principle. It is natural circulation without any machinery or human intervention. The vacuum is best to insulate than any artificial material. There is zero heating exchange between the inner glass and outer glass. The structure of the vacuum tube traps 90% of solar energy. This happens over and over again until the temperature of the water reaches the boiling point.
          </p>
          <h2>Analysis Diagram</h2>
          <div className='analysis-diagram'>
            <img src={analysisDiagram} alt="Analysis Diagram Image" />  
          </div>
          <h3>Characteristics:</h3>
          <p>
            <strong>1. Quality low-cost produce and no running cost</strong><br />
            Pay once only while you enjoy it for over 20 years. Solar energy is an abundant source of renewable energy. Meanwhile, Hi-Tech Solar geyser according to the thermosiphon principle makes the water auto circulation without any other equipment. Due to the gravity principle, the low-pressure vessel is cost-effective to manufacture than the high-pressure vessels.
          </p>
          <p>
            <strong>2. No corrosion</strong><br />
            In damp weather, metal is prone to corrosion. At Hi-Tech Solar we use a 304-2B stainless steel tank to combat against corrosion. This material can effectively prolong the life of the product. The frame is strong, attractive, and corrosion-resistant.
          </p>
          <p>
            <strong>3. Good heat preservation</strong><br />
            The most important function of vacuum tube is to insulate the inner tube from heat loss. This means once the heat is absorbed, it is transferred to the water in the manifold and no lost to the outside environment. Because the water is in direct contact with the inner glass tube the heat transfer is very effective and efficient. The performance of the Hi-Tech Solar evacuated tube itself can reach 90%, however by the heat losses from the tank, the total system efficiency is around 80-85% (depending on the surrounding temperature). The insulating foam of the geyser is 5 cm which is designed for South African weather.
          </p>
          <p>
            <strong>4. No Scale</strong><br />
            High temperature will form some scale within the vacuum tube, which will not disable the whole system.
          </p>
          <p>
            <strong>5. Anti-hail function</strong><br />
            Hi-Tech Solar glass evacuated tube has the perfect anti-hail performance. The tube is made of extremely strong transparent borosilicate glass that is able to resist impact from hail of up to 25mm in diameter.
          </p>
        </div>
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

export default SolarGeyserPage;