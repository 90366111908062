import React from 'react';
import '../styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="email">
            <a href="mailto:sales@hi-techsolargeysers.co.za">sales@hi-techsolargeysers.co.za</a>
          </div>
          <br />
          <div className="disclaimer">
            Please be informed that we do not sell DIY packages. If your solar geyser was purchased elsewhere, it is not our product. Our installations are exclusively conducted in Gauteng. Beware of imitations and inferior quality geysers from other sources.
          </div>
          <br />
          <div className="copyright">
            &copy; Copyright © 2024. www.hi-techsolargeysers.co.za .
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;